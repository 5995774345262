import jwtDecode from 'jwt-decode';
import { APICore } from './apiCore';
import { UserRepository } from './user';
import { UserRoleEnum } from 'config/enum';
import { DbId } from 'data/types';

const api = new APICore();

async function login(params: { email: string; password: string}) {
    const response = await api.create(`${api.endpoints.login}`, {...params, web: true});
    if (response.data?.error) return response;
    if (response.status >= 400) return response;
    const token = response?.data?.access;
    if (!token) return null;

    const refresh = response?.data?.refresh;
    const decoded: any = jwtDecode(token);
    if (!decoded.user_id) return null;

    UserRepository.api.refreshAuthorization(token);
    const userResponse = await UserRepository.get(decoded.user_id);

    if (!userResponse) return userResponse;
    if (userResponse.status >= 400) return null;
    if (!userResponse.data) return userResponse;
    if (userResponse.data instanceof String) return null;
    if (userResponse?.data?.error) return userResponse;

    return {
        ...userResponse,
        data: {
            ...userResponse.data,
            token: token,
            refresh: refresh,
            role: UserRoleEnum.Store,
        },
    };
}

function logout() {
    return api.create(`${api.endpoints.logout}`, {});
}

function signup(params: { first_name: string; last_name: string; phone_number: string; email: string; password: string }) {
    return api.create(`${api.endpoints.user}`, params);
}

function forgotPassword(params: { email: string }) {
    return api.create(`${api.endpoints.forgotPassword}`, params);
}

function recoverPassword(params: { password: string, id: number } ) {
    return api.create(`${api.endpoints.recoverPassword}${params.id}`, { password: params.password});
}

function forgotPasswordConfirm(params: { email: string }) {
    return api.create(`${api.endpoints.resetPassword}`, params);
}

async function getUser({ id }: {id: DbId }) {
    return UserRepository.get(id);
}

export { login, logout, signup, forgotPassword, recoverPassword, forgotPasswordConfirm, getUser };
