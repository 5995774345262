import { faker } from '@faker-js/faker';
import { Store, StoreList } from 'data/types';
import { addressMock } from './address';

export const storeMock = (data?: Partial<Store>): Store => ({
    id: faker.number.int(),
    name: faker.company.name(),
    franchise: faker.datatype.boolean(), // missing
    // cupom: faker.string.alphanumeric(),
    // available_balance: faker.number.float(),
    // available_stock: faker.number.int(),
    // blocked: faker.datatype.boolean(),
    // email: faker.internet.email(),
    logo_image: faker.image.urlPicsumPhotos(),
    card_image: faker.image.urlPicsumPhotos(),
    // about_title: faker.word.sample(),
    // about_photos: faker.image.urlPicsumPhotos(),
    about_text: faker.word.sample(),
    // expositor_limit: faker.number.int({ max: 200 }),
    created_date: faker.date.past().toISOString(),
    // birth_date: faker.date.past().toISOString(),
    // clothes_size: faker.word.words(),
    phone_number: faker.phone.number(),
    // last_chat_read: faker.date.past().toISOString(),
    // last_login_date: faker.date.past().toISOString(),
    // last_sale_date: faker.date.past().toISOString(),
    // luxury: faker.datatype.boolean(),
    social: {
        instagram: faker.internet.url(),
        facebook: faker.internet.url(),
        youtube: faker.internet.url(),
        tiktok: faker.internet.url(),
    },
    // address_id: faker.number.int(),
    address: addressMock(),
    ambassador_tag: null,
    bank_account: {
        id: faker.number.int(),
        user: null,
        banks_name: faker.lorem.sentences({ min: 1, max: 4 }),
        store_name: faker.lorem.sentences({ min: 1, max: 4 }),
        agency: faker.finance.pin(6),
        account: faker.finance.accountNumber(),
        banks: faker.number.int(),
        store: faker.number.int(),
        account_type: 'corrente',
        owner_account: faker.person.fullName(),
        owner_cpfcnpj: '12345678900',
    },
    cnpj: faker.number.int().toString(),
    has_products_with_search: faker.datatype.boolean(),
    product_count: faker.number.int({ min: 0, max: 1000 }),
    profile_completed: faker.datatype.boolean(),
    user: faker.number.int(),
    agorachei_pro: faker.datatype.boolean(),
    use_shared_price: faker.datatype.boolean(),
    long_description: faker.company.name(),
    ...data,
});

export const storeListMock = ({ limit = 10, page = 1 } = {}): StoreList => {
    const list = Array(100)
        .fill(0)
        .map(() => storeMock());
    return {
        results: list.slice((page - 1) * limit, page * limit),
        next: '',
        previous: '',
        count: 100,
    };
};
